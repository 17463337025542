import React, { useEffect, useRef, useState } from 'react';
import Pricing from '../.shared/Pricing';

import styles from './VialHero.module.css';
import tick from '../../assets/tick.png';
import tick350 from '../../assets/tick350.png';
import semaglutide from '../../assets/Semaglutide.png';
// import semaglutide350 from '../../assets/Semaglutide350.png';
import tirzepatide from '../../assets/Tirzepatide.png';
// import tirzepatide350 from '../../assets/Tirzepatide350.png';
import circleright from '../../assets/circle-right.svg';
import boderline from "../../assets/boderline.png";



function VialHero() {
  const [isVisible, setIsVisible] = useState(false);
  const [isSemaglutide, setIsSemaglutide] = useState(true);
  const elementRef = useRef(null);
  const semaBubbleRef = useRef(null);
  const tirzBubbleRef = useRef(null);
  const semaDotWhackRef = useRef(null);
  const tirzDotWhackRef = useRef(null);
  const pricing = Pricing();
  const prices = pricing.getPrices();
  const tickImg = window.innerWidth < 400 ? tick350 : tick;



  

  const handleSemaHover = (e) => {
    e.target.classList.add('animate__animated', 'animate__pulse', styles.glowSemaglutide);
  };
  const handleSemaHoverEnd = (e) => {
    e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowSemaglutide);
  };
  const handleTirzHover = (e) => {
    e.target.classList.add('animate__animated', 'animate__pulse', styles.glowTirzepatide);
  };
  const handleTirzHoverEnd = (e) => {
    e.target.classList.remove('animate__animated', 'animate__pulse', styles.glowTirzepatide);
  };
  const handleSemaClick = () => {
    setIsSemaglutide(true);
    semaBubbleRef.current.classList.add(styles.semaBackground);
    semaDotWhackRef.current.classList.add(styles.yellow);
    tirzBubbleRef.current.classList.remove(styles.tirzBackground);
    tirzDotWhackRef.current.classList.remove(styles.yellow);
    
    let prices = document.getElementsByClassName(styles.price);
    for (let i = 0; i < prices.length; i++) {
      prices[i].classList.add('animate__animated', 'animate__flipInX');
    }
  };
  const handleTirzClick = () => {
    setIsSemaglutide(false);
    semaBubbleRef.current.classList.remove(styles.semaBackground);
    semaDotWhackRef.current.classList.remove(styles.yellow);
    tirzBubbleRef.current.classList.add(styles.tirzBackground);
    tirzDotWhackRef.current.classList.add(styles.yellow);
  };
  const handleSemaStarterClick = () => {
    const selectedPlan = prices.semaglutide.starter.fourWeeks ?? prices.semaglutide.starter.threeMonths;
    localStorage.setItem('price_id', selectedPlan.price_id);
    window.location.href = '/checkout';
  };
  const handleSemaPremiumClick = () => {
    const selectedPlan = prices.semaglutide.premium.fourWeeks ?? prices.semaglutide.premium.threeMonths;
    localStorage.setItem('price_id', selectedPlan.price_id);
    window.location.href = '/checkout';
  };
  const handleTirzStarterClick = () => {
    const selectedPlan = prices.tirzepatide.starter.fourWeeks ?? prices.tirzepatide.starter.threeMonths;
    localStorage.setItem('price_id', selectedPlan.price_id);
    window.location.href = '/checkout';
  };
  const handleTirzPremiumClick = () => {
    const selectedPlan = prices.tirzepatide.premium.fourWeeks ?? prices.tirzepatide.premium.threeMonths;
    localStorage.setItem('price_id', selectedPlan.price_id);
    window.location.href = '/checkout';
  };

  useEffect(() => {

      //TODO: Look up previous selection
      semaBubbleRef.current.classList.add(styles.semaBackground);
      semaDotWhackRef.current.classList.add(styles.yellow);

      const observer = new IntersectionObserver(
          ([entry]) => {
              if (entry.isIntersecting) {
                  setIsVisible(true);
              }
          },
          {
              threshold: 0.5,
          }
      );

      let currentElement = elementRef.current;
      if (currentElement) {
          observer.observe(currentElement);
      }
      return () => {
          if (currentElement) {
              observer.unobserve(currentElement);
          }
      };
  }, []);
  
  return (
    <div className={styles.vialHero}>
      <div className={styles.banner}>
        <div className={styles.bannerText + ' animate__animated animate__flip'}>
          <div className={styles.bannerSubtitle}>Safe, Effective, Proven</div>
          <div className={styles.bannerTitle}>Weight Loss</div>
        </div>
        <img className={styles.headerRule} src={boderline} alt='' />
      </div>

      <div className={styles.mainPanel}>
        <div className={styles.medBar}>
          <div className={styles.semaglutide} onClick={handleSemaClick}>
            <div ref={semaBubbleRef} className={styles.bubble} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>
              Semaglutide
              <div ref={semaDotWhackRef} className={styles.dotWhack + ' ' + styles.left}>
                <span className={styles.dotWhackPercent}>17<sup>%</sup></span><br/>avg weight loss
              </div>
            </div>
          </div>
          <div className={styles.tirzepatide} onClick={handleTirzClick}>
            <div ref={tirzBubbleRef} className={styles.bubble} onMouseEnter={handleTirzHover} onMouseLeave={handleTirzHoverEnd}>
              Tirzepatide
              <div ref={tirzDotWhackRef} className={styles.dotWhack + ' ' + styles.right}>
                <span className={styles.dotWhackPercent}>24<sup>%</sup></span><br/>avg weight loss
              </div>
            </div>
          </div>
        </div>
        <div className={styles.medPlans}>
          <div className={styles.medPlan + ' ' + styles.semaBorder} style={isSemaglutide ? {display:'flex'} : {display:'none'}}>
            <div className={styles.medPlanBox}>
              <div className={styles.medPlanImg}>
                <img ref={elementRef} className={styles.vialImg + (isVisible ? ' animated animate__animated animate__rubberBand' : '')} src={semaglutide} alt='' />
                <div className={styles.sameActiveSmall}>Semaglutide is a GLP-1 receptor agonist that is injected once a week. It is the active pharmaceutical ingredient in Ozempic<sup>&#174;</sup> and Wegovy<sup>&#174;</sup></div>
              </div>
              <div className={styles.medPlanDesc}>
                <div className={styles.plans}>            
                  <div className={styles.price + ' ' + styles.semaPlanBorder} onClick={handleSemaStarterClick}>
                    <div className={styles.planTitle + ' ' + styles.semaBackground}>
                      Starter
                      <div className={styles.dose + ' ' + styles.semaBackground}>up to {prices.semaglutide.starter.fourWeeks?.dose}</div>
                    </div>
                    <div className={styles.dollars}><sup>$</sup>{prices.semaglutide.starter.fourWeeks?.total}<span>/mo</span></div>                  
                    <div className={styles.letsGo + ' ' + styles.semaBackground} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleright} alt='Start' /></div>
                  </div>
                  <div className={styles.price + ' ' + styles.semaPlanBorder} onClick={handleSemaPremiumClick}>
                    <div className={styles.planTitle + ' ' + styles.semaBackground}>
                      Premium
                      <div className={styles.dose + ' ' + styles.semaBackground}>up to {prices.semaglutide.premium.fourWeeks?.dose}*</div>
                    </div>
                    <div className={styles.dollars}><sup>$</sup>{prices.semaglutide.premium.fourWeeks?.total}<span>/mo</span></div>                  
                    <div className={styles.letsGo + ' ' + styles.semaBackground} onMouseEnter={handleSemaHover} onMouseLeave={handleSemaHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleright} alt='Start' /></div>
                  </div>
                </div>
                <div className={styles.startingAt}>All-inclusive pricing. Two month minimum.</div>
              </div>
            </div>
            <div className={styles.weightLoss}>
              <div className={styles.sameActive}>
                Semaglutide is the active pharmaceutical ingredient in Ozempic<sup>&#174;</sup> and Wegovy<sup>&#174;</sup>
              </div>
            </div>
          </div>


          <div className={styles.medPlan + ' ' + styles.tirzBorder} style={isSemaglutide ? {display:'none'} : {display:'flex'}}>
            <div className={styles.medPlanBox}>
              <div className={styles.medPlanImg}>
                <img ref={elementRef} className={styles.vialImg + (isVisible ? ' animated animate__animated animate__rubberBand' : '')} src={tirzepatide} alt='' />
                <div className={styles.sameActiveSmall}>Tirzepatide is both a GLP-1 and GIP receptor agonist that is injected once a week. It is the active pharmaceutical ingredient in Mounjaro<sup>&#174;</sup> and Zepbound<sup>&#174;</sup></div>
              </div>
              <div className={styles.medPlanDesc}>
                <div className={styles.plans}>            
                  <div className={styles.price + ' ' + styles.tirzPlanBorder} onClick={handleTirzStarterClick}>
                    <div className={styles.planTitle + ' ' + styles.tirzBackground}>
                      Starter
                      <div className={styles.dose + ' ' + styles.tirzBackground}>up to {prices.tirzepatide.starter.fourWeeks?.dose}</div>
                    </div>       
                    <div className={styles.dollars}><sup>$</sup>{prices.tirzepatide.starter.fourWeeks?.total}<span>/mo</span></div>                  
                    <div className={styles.letsGo + ' ' + styles.tirzBackground} onMouseEnter={handleTirzHover} onMouseLeave={handleTirzHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleright} alt='Start' /></div>
                  </div>
                  <div className={styles.price + ' ' + styles.tirzPlanBorder} onClick={handleTirzPremiumClick}>
                    <div className={styles.planTitle + ' ' + styles.tirzBackground}>
                      Premium
                      <div className={styles.dose + ' ' + styles.tirzBackground}>up to {prices.tirzepatide.premium.fourWeeks?.dose}*</div>
                    </div>
                    <div className={styles.dollars}><sup>$</sup>{prices.tirzepatide.premium.fourWeeks?.total}<span>/mo</span></div>                  
                    <div className={styles.letsGo + ' ' + styles.tirzBackground} onMouseEnter={handleTirzHover} onMouseLeave={handleTirzHoverEnd}>Let's Go!<img className={styles.buttonArrow} src={circleright} alt='Start' /></div>
                  </div>
                </div>
                <div className={styles.startingAt}>All-inclusive pricing. Two month minimum.</div>                
              </div>
            </div>
            <div className={styles.weightLoss}>
              <div className={styles.sameActive}>
                Tirzepatide is the active pharmaceutical ingredient in <b>Mounjaro<sup>&#174;</sup></b> and <b>Zepbound<sup>&#174;</sup></b>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.higherDoses}>*Contact us at <a href="mailto:social@tb2.health">social@tb2.health</a> for pricing on higher doses</div>
      </div>

      <div className={styles.ticks}>
          <div className={styles.tick}>
            <div className={styles.tickText}>All Plans Include:</div>
          </div>
          <div className={styles.tick}>
            <img className={styles.tickImg} src={tickImg} alt=''></img>
            <div className={styles.tickText}>Transparent Pricing / NO Hidden Fees</div>
          </div>
          <div className={styles.tick}>
            <img className={styles.tickImg} src={tickImg} alt=''></img>
            <div className={styles.tickText}>Clinician and Patient Care Coordinator</div>
          </div>
          <div className={styles.tick}>
            <img className={styles.tickImg} src={tickImg} alt=''></img>
            <div className={styles.tickText}>Prescription Medications and Supplies</div>
          </div>
          <div className={styles.tick}>
            <img className={styles.tickImg} src={tickImg} alt=''></img>
            <div className={styles.tickText}>NO COST If You Don't Qualify</div>
          </div>
          <div className={styles.tick}>
            <img className={styles.tickImg} src={tickImg} alt=''></img>
            <div className={styles.tickText}>Receive Medication in 1 Week</div>
          </div>
          <div className={styles.tick}>
            <img className={styles.tickImg} src={tickImg} alt=''></img>
            <div className={styles.tickText}>FREE Shipping</div>
          </div>
          <div className={styles.tick}>
            <img className={styles.tickImg} src={tickImg} alt=''></img>
            <div className={styles.tickText}>Clinical Review Every 4 Weeks</div>
          </div>
          <div className={styles.tick}>
            <img className={styles.tickImg} src={tickImg} alt=''></img>
            <div className={styles.tickText}>24/7 Messaging Platform</div>
          </div>
          <div className={styles.tick}>
            <img className={styles.tickImg} src={tickImg} alt=''></img>
            <div className={styles.tickText}>Tips To Keep Weight Off</div>
          </div>
        </div>
        <div className={styles.divider}>
          <div className={styles.dividerBar}></div>
        </div>
      </div>
  );
}

export default VialHero;