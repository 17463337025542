const prodTestPlan = {
    name: 'Test Plan',
    title: 'Test Plan',
    startingAt: '1',
    total: '1',
    url: 'https://buy.stripe.com/14k02Y5sSaTEaFqeV9',
    price_id: 'price_1OIOHfBpdcZ3Pb9Q3WzGtARD'    
}

const pricingBySource = 
{
    default: {
        semaglutide : {
            starter : {
                fourWeeks: {
                    name : 'Semaglutide Starter - 4 Weeks',
                    title: 'Semaglutide Starter',
                    dose: '0.25mg per dose',
                    startingAt: '249',
                    total: '249',
                    url: '',
                    price_id: 'price_1PvqbhBpdcZ3Pb9QNfFyIQZa'
                }
            },
            premium : {
                fourWeeks: {
                    name : 'Semaglutide Premium - 4 Weeks',
                    title: 'Semaglutide Premium',
                    dose: '1mg per dose',
                    startingAt: '299',
                    total: '299',
                    url: '',
                    price_id: 'price_1PLvrmBpdcZ3Pb9Q5lCalNsp'
                }
            },
            maintenance : {
                sixMonths: {
                    name : 'Semaglutide Maintenance - 6 Months',
                    title: 'Semaglutide Maintenance',
                    dose: '0.25mg every 10-14 days',
                    startingAt: '499',
                    total: '499',
                    url: '',
                    price_id: 'price_1Phz4xBpdcZ3Pb9Qxc2fRjpn'                
                }
            }
        },
        tirzepatide : {
            starter : {
                fourWeeks: {
                    name : 'Tirzepatide Starter - 4 Weeks',
                    title: 'Tirzepatide Starter',
                    dose: '5mg per dose',
                    startingAt: '429',
                    total: '429',
                    url: '',
                    price_id: 'price_1PvqgSBpdcZ3Pb9Q0Vng9NBt'
                }
            },
            premium : {
                fourWeeks: {
                    name : 'Tirzepatide Premium - 4 Weeks',
                    title: 'Tirzepatide Premium',
                    dose: '10mg per dose',
                    startingAt: '589',
                    total: '589',
                    url: '',
                    price_id: 'price_1PvqhhBpdcZ3Pb9QFNnslDX6'
                }
            },
            maintenance : {
                sixMonths: {
                    name : 'Tirzepatide Maintenance - 6 Months',
                    title: 'Tirzepatide Maintenance',
                    dose: '2.5mg every 7-10 days',
                    startingAt: '799',
                    total: '799',
                    url: '',
                    price_id: 'price_1Phz3ZBpdcZ3Pb9QiOwVgJd3'                
                }
            }
        },
        coupons: [ 
            {
                name: 'Podcast',
                id: 'CnBKOncx',
                discount: 0.05
            }
        ]
    },
    mma: {
        semaglutide : {
            starter : {
                fourWeeks: {
                    name : 'Semaglutide Starter - 4 Weeks',
                    title: 'Semaglutide Starter',
                    dose: '0.25mg per dose',
                    startingAt: '230',
                    total: '230',
                    url: '',
                    price_id: 'price_1PvqjfBpdcZ3Pb9Q6vOSsD2N'
                }
            },
            premium : {
                fourWeeks: {
                    name : 'Semaglutide Premium - 4 Weeks',
                    title: 'Semaglutide Premium',
                    dose: '1mg per dose',
                    startingAt: '277',
                    total: '277',
                    url: '',
                    price_id: 'price_1PvqkeBpdcZ3Pb9Q2nsW5STT'
                }
            },
            maintenance : {
                sixMonths: {
                    name : 'Semaglutide Maintenance - 6 Months',
                    title: 'Semaglutide Maintenance',
                    dose: '0.25mg every 10-14 days',
                    startingAt: '460',
                    total: '460',
                    url: '',
                    price_id: 'price_1PieXyBpdcZ3Pb9Q4QkJ1i7g'                
                }
            }
        },
        tirzepatide : {
            starter : {
                fourWeeks: {
                    name : 'Tirzepatide Starter - 4 Weeks',
                    title: 'Tirzepatide Starter',
                    dose: '5mg per dose',
                    startingAt: '397',
                    total: '397',
                    url: '',
                    price_id: 'price_1PvqnCBpdcZ3Pb9QT517wCJn'
                }
            },
            premium : {
                fourWeeks: {
                    name : 'Tirzepatide Premium - 4 Weeks',
                    title: 'Tirzepatide Premium',
                    dose: '10mg per dose',
                    startingAt: '545',
                    total: '545',
                    url: '',
                    price_id: 'price_1PvqoUBpdcZ3Pb9QFwNh0NAK'
                }
            },
            maintenance : {
                sixMonths: {
                    name : 'Tirzepatide Maintenance - 6 Months',
                    title: 'Tirzepatide Maintenance',
                    dose: '2.5mg every 7-10 days',
                    startingAt: '736',
                    total: '736',
                    url: '',
                    price_id: 'price_1PiebiBpdcZ3Pb9QLBxPZAgg'                
                }
            }
        },
        coupons: [ 
            {
                name: 'Podcast',
                id: 'CnBKOncx',
                discount: 0.05
            }
        ]
    },
    test: {
        semaglutide : {
            starter : {
                fourWeeks: {
                    name : 'Semaglutide Starter - 4 Weeks',
                    title: 'Semaglutide Starter',
                    dose: '0.25mg per dose',
                    startingAt: '248',
                    total: '248',
                    url: '',
                    price_id: 'price_1PieNaBpdcZ3Pb9Q637zM4Zb'
                },
            },
            premium : {
                fourWeeks: {
                    name : 'Semaglutide Premium - 4 Weeks',
                    title: 'Semaglutide Premium',
                    dose: '1mg per dose',
                    startingAt: '299',
                    total: '299',
                    url: '',
                    price_id: 'price_1PieOZBpdcZ3Pb9Q9gdQYLNW'
                }
            },
            maintenance : {
                sixMonths: {
                    name : 'Semaglutide Maintenance - 6 Months',
                    title: 'Semaglutide Maintenance',
                    dose: '0.25mg every 10-14 days',
                    startingAt: '499',
                    total: '499',
                    url: '',
                    price_id: 'price_1PiePNBpdcZ3Pb9QmX1Dx28h'                
                }
            }
        },
        tirzepatide : {
            starter : {
                fourWeeks: {
                    name : 'Tirzepatide Starter - 4 Weeks',
                    title: 'Tirzepatide Starter',
                    dose: '5mg per dose',
                    startingAt: '499',
                    total: '499',
                    url: '',
                    price_id: 'price_1PieQ7BpdcZ3Pb9QBuYN4xDx'
                }
            },
            premium : {
                fourWeeks: {
                    name : 'Tirzepatide Premium - 4 Weeks',
                    title: 'Tirzepatide Premium',
                    dose: '10mg per dose',
                    startingAt: '699',
                    total: '699',
                    url: '',
                    price_id: 'price_1PieQgBpdcZ3Pb9Q6dgjrZat'
                }
            },
            maintenance : {
                sixMonths: {
                    name : 'Tirzepatide Maintenance - 6 Months',
                    title: 'Tirzepatide Maintenance',
                    dose: '2.5mg every 7-10 days',
                    startingAt: '799',
                    total: '799',
                    url: '',
                    price_id: 'price_1PieRbBpdcZ3Pb9QHgj969yf'                
                }
            }
        },
        coupons: [ 
            {
                name: 'Podcast',
                id: '0W6xK4VT',
                discount: 0.05
            }
        ]
    },
    archived: {
        default: {
            semaglutide : {
                starter : {
                    monthly: {
                        name: 'Semaglutide Starter - Monthly',
                        title: 'Semaglutide Starter',
                        dose: '0.25mg',
                        total: '349',
                        url: 'https://buy.stripe.com/dR6eXS4oO6DoaFq5kL',
                        price_id: 'price_1OLqryBpdcZ3Pb9QDtnpMq3U'                        
                    },
                    threeMonths: {
                        name: 'Semaglutide Starter - 3 Months',
                        title: 'Semaglutide Starter',
                        dose: '0.25mg',
                        startingAt: '299',
                        total: '897',
                        url: 'https://buy.stripe.com/14k02YdZogdYeVG4gI',
                        price_id: 'price_1ONNRIBpdcZ3Pb9QY60baHnK'
                    }
                },
                premium : {
                    monthly: {
                        name: 'Semaglutide Premium - Monthly',
                        title: 'Semaglutide Premium',
                        dose: '0.5mg',
                        total: '499',
                        url: 'https://buy.stripe.com/3cs3fabRg4vg3cY28B',
                        price_id: 'price_1ONNYzBpdcZ3Pb9Q3yidnalK'
                    },
                    threeMonths: {
                        name: 'Semaglutide Premium - 3 Months',
                        title: 'Semaglutide Premium',
                        dose: '0.5mg',
                        startingAt: '449',
                        total: '1,347',
                        url: 'https://buy.stripe.com/cN23fa7B07HsbJuaF8',
                        price_id: 'price_1ONNXMBpdcZ3Pb9QwtXv5M4X'
                    }
                }
            },
            tirzepatide : {
                starter : {
                    monthly: {
                        name: 'Tirzepatide Starter - Monthly',
                        title: 'Tirzepatide Starter',
                        dose: '5mg',
                        total: '649',
                        url: 'https://buy.stripe.com/6oE8zu2gGbXI7te14z',
                        price_id: 'price_1OLtmEBpdcZ3Pb9Qg1UeHDtG'
                    },
                    threeMonths: {
                        name: 'Tirzepatide Starter - 3 Months',
                        title: 'Tirzepatide Starter',
                        dose: '5mg',
                        startingAt: '599',
                        total: '1,797',
                        url: 'https://buy.stripe.com/bIY172f3s2n800M00w',
                        price_id: 'price_1ONNaPBpdcZ3Pb9Qzb8cliRS'
                    }
                },
                premium : {
                    monthly: {
                        name: 'Tirzepatide Premium - Monthly',
                        title: 'Tirzepatide Premium',
                        dose: '10mg',
                        total: '1,049',
                        url: 'https://buy.stripe.com/14kg1W7B02n8fZKbJf',
                        price_id: 'price_1ONHdGBpdcZ3Pb9QhBVbIaVB'
                    },
                    threeMonths: {
                        name: 'Tirzepatide Premium - 3 Months',
                        title: 'Tirzepatide Premium',
                        dose: '10mg',
                        startingAt: '999',
                        total: '2,997',
                        url: 'https://buy.stripe.com/3cs6rm1cC8Lw00MeVs',
                        price_id: 'price_1ONNVNBpdcZ3Pb9QEr5f9asz'
                    }
                }
            }
        },
        mma: {
            semaglutide : {
                starter : {
                    monthly: {
                        name: 'Semaglutide Starter - Monthly',
                        title: 'Semaglutide Starter',
                        dose: '0.25mg',
                        total: '349',
                        url: 'https://buy.stripe.com/dR6eXS4oO6DoaFq5kL',
                        price_id: 'price_1OLqryBpdcZ3Pb9QDtnpMq3U'                        
                    },
                    threeMonths: {
                        name: 'Semaglutide Starter - 3 Months',
                        title: 'Semaglutide Starter',
                        dose: '0.25mg',
                        startingAt: '299',
                        total: '897',
                        url: 'https://buy.stripe.com/14k02YdZogdYeVG4gI',
                        price_id: 'price_1ONNRIBpdcZ3Pb9QY60baHnK'
                    }
                },
                premium : {
                    monthly: {
                        name: 'Semaglutide Premium - Monthly',
                        title: 'Semaglutide Premium',
                        dose: '0.5mg',
                        total: '499',
                        url: 'https://buy.stripe.com/3cs3fabRg4vg3cY28B',
                        price_id: 'price_1ONNYzBpdcZ3Pb9Q3yidnalK'
                    },
                    threeMonths: {
                        name: 'Semaglutide Premium - 3 Months',
                        title: 'Semaglutide Premium',
                        dose: '0.5mg',
                        startingAt: '449',
                        total: '1,347',
                        url: 'https://buy.stripe.com/cN23fa7B07HsbJuaF8',
                        price_id: 'price_1ONNXMBpdcZ3Pb9QwtXv5M4X'
                    }
                }
            },
            tirzepatide : {
                starter : {
                    monthly: {
                        name: 'Tirzepatide Starter - Monthly',
                        title: 'Tirzepatide Starter',
                        dose: '5mg',
                        total: '649',
                        url: 'https://buy.stripe.com/6oE8zu2gGbXI7te14z',
                        price_id: 'price_1OLtmEBpdcZ3Pb9Qg1UeHDtG'
                    },
                    threeMonths: {
                        name: 'Tirzepatide Starter - 3 Months',
                        title: 'Tirzepatide Starter',
                        dose: '5mg',
                        startingAt: '599',
                        total: '1,797',
                        url: 'https://buy.stripe.com/bIY172f3s2n800M00w',
                        price_id: 'price_1ONNaPBpdcZ3Pb9Qzb8cliRS'
                    }
                },
                premium : {
                    monthly: {
                        name: 'Tirzepatide Premium - Monthly',
                        title: 'Tirzepatide Premium',
                        dose: '10mg',
                        total: '1,049',
                        url: 'https://buy.stripe.com/14kg1W7B02n8fZKbJf',
                        price_id: 'price_1ONHdGBpdcZ3Pb9QhBVbIaVB'
                    },
                    threeMonths: {
                        name: 'Tirzepatide Premium - 3 Months',
                        title: 'Tirzepatide Premium',
                        dose: '10mg',
                        startingAt: '999',
                        total: '2,997',
                        url: 'https://buy.stripe.com/3cs6rm1cC8Lw00MeVs',
                        price_id: 'price_1ONNVNBpdcZ3Pb9QEr5f9asz'
                    }
                }
            }
        }        
    }
};


function findPricingBySource() {
    let prices = pricingBySource.default;
    
    const subdomain = localStorage.getItem('subdomain');
    const src = localStorage.getItem('source');

    if (src === 'mma' || subdomain === 'mma' || src === 'axcethr' || src === 'benefithub') {
        prices = pricingBySource.mma;
    }
    if (process.env.REACT_APP_TEST_PRICING === 'true') {
        prices = pricingBySource.test;
    }
    return prices;
}

function findPlanByPriceId(priceId) {
    let pricing = findPricingBySource();

    for (let med in pricing) {
        for (let level in pricing[med]) {
            for (let period in pricing[med][level]) {
                if (pricing[med][level][period].price_id === priceId) {
                    //console.log('found plan', plans[med][level][period]);
                    return pricing[med][level][period];
                }
            }
        }
    }
    return null;
}

function findPlans() {
    let items = [];
    let pricing = findPricingBySource();
    
    function recurse(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                recurse(obj[key]);
            } else if (key === 'monthly' || key === 'threeMonths') {
                items.push(obj);
            }
        }
    }
    
    recurse(pricing);
    return items;
}

function Pricing(src) {


    return {
        getPrices: () => {
            return findPricingBySource();
        },
        getPlan: (priceId) => {
            return findPlanByPriceId(priceId);
        },
        getPlans: () => {
            return findPlans();
        },
        getTestPlan: () => {
            return prodTestPlan;
        }
    }
}

export default Pricing;