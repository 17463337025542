import { React, useEffect } from 'react';
import Header from '../.shared/header';
import Footer from '../.shared/footer';
import Steps from './Steps';
import Support from './Support';
import SuccessStories from './SuccessStories';
// import QuestionHero from './QuestionHero';
import VialHero from './VialHero';
import StartMyJourney from './StartMyJourney';



    

function Landing() {

    // useEffect(() => {
        const subdomain = window.location.host.split('.')[0];
        localStorage.setItem('subdomain', subdomain);    

        const pathParts = window.location.pathname.split('/');
        const path = pathParts.length > 1 ? pathParts[1].toLowerCase() : '';
        if (path === 'podcast') {
            localStorage.setItem('discount', path);
            localStorage.setItem('source', 'podcast');
        }
        else if (path === 'ioa' || path === 'axcethr' || path === 'mma' || path === 'grace') {
            localStorage.setItem('source', path);
        }
        else if (path === 'b' || path === 'bh' || path === 'benefithub') {
            localStorage.setItem('source', 'benefithub');
        }
        else if (localStorage.getItem('source') === null) {
            localStorage.setItem('source', subdomain);
        }
    // }, []);


    return (
        <>
            <Header />
            {/* <QuestionHero /> */}
            <VialHero />
            <Support />
            <Steps />
            <StartMyJourney />
            <SuccessStories />
            <Footer />
        </>
    );
}

export default Landing;
